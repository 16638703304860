
// import IV from "../images/ivf-process.png";
// import mater from "../images/pregnant.jpg";
// import pedia from "../images/pediatric.jpg";
// import gynaec from "../images/gynaec.jpg";
// import dermat from "../images/dermatology.jpg";

// import iui from "../images/iui-treatment.jpg";
// import icon1 from "../images/icons/ivf.png";
import icon_fert from "../images/icons-i/tooth.png";
import icon_di from "../images/icons-i/clinic (1).png";
import icon_rc from "../images/icons-i/clinic (2).png";
import icon_ib from "../images/icons-i/clinic (4).png";
import icon_cd from "../images/icons-i/dental-care.png";
import icon1 from "../images/Instant-Teeth-Whitening.jpg";
import icon2 from "../images/Cosmetic-1.jpg";
import icon3 from "../images/implant_dental.jpg";
import icon4 from "../images/root-canal.jpg";
import icon5 from "../images/invisible.jpg";
// import icon6 from "../images/icons-i/clinic (4).png";
// import icon_spec from "../images/icons-i/nurse.png";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React from "react";
import fert from "../images/gallery/1.jpg";
// import Service from '../component/Service';
const ServDesc = [

  {
    id: 1,
    
    ServiceIcon: icon_cd,
    ServiceName: "Cosmetic Dentistry",
    ServicePara1:
      "A billion-dollar smile is one’s biggest asset. A smile can create lasting impressions on others and enhance your personality. People with beautiful smiles are more confident souls. ",

    ServicePara2:
      " Ask yourself these questions “Do I have a perfect smile?” “Do I have a selfie-friendly smile?” “Does my smile get me compliments galore?.If yes, you are blessed with nature’s best gift. But if you are among those who shy to smile owing to misaligned, crooked, protruding, disjointed, irregular, gapped, stained and discolored teeth, you need to consider a smile makeover and bring back the confidence in your life.",
    ServicePara3:
      "With revolutionary technology and expertise, it is very much possible. ",
    ServicePara4:
      "OraMax dental clinic , Juhu specializes in smile makeovers to enhance your personality, boost your self-image and confidence and enable you to fall in love with your own smile. OraMax believe that you must love yourself to be able to create lasting impressions on others.",
    ServiceImg: icon2,
    MetaTitle: "Cosmetic Dentistry Clinic In Juhu | Oramax",
    MetaDes: "Looking for a cosmetic dentistry clinic in Juhu? Transform your smile at our advanced clinic with personalized cosmetic dental treatments.",
    CanonicalTag: "https://oramax.in/cosmetic-dentistry-clinic-juhu",
    Slugs:"/cosmetic-dentistry-clinic-juhu",
  },

  {
    id: 2,
    ServiceIcon: icon_fert,
    ServiceName: "Instant Teeth Whitening",
    ServicePara1:
      "Zoom teeth whitening / teeth bleaching is a revolutionary concept in teeth whitening. We at OraMax dental clinic have a specialized machine for this purpose. We proudly are one of the very few dental clinics in Mumbai to have this high tech technology and treatment.",
    ServicePara2:
      "Most Bollywood celebrities, models and professionals prefer zoom teeth whitening/teeth bleaching as it augments their smiles, prepares them for last minute makeovers and boosts their confidence, be it on the ramp or while shooting in front of the camera. ",
    ServicePara3:
      "Likewise, professionals at work who have important presentations to make or conferences to attend tend to benefit with this amazing Smile Makeover technology. ",
    ServicePara4:
      " Best of all, true to its name, you get instant sparkling white teeth. So say goodbye to your discoloured and stained teeth. Just walk in at OraMax and walk out flashing your beautiful smile.",

    ServiceImg: icon1,
    MetaTitle: "Instant Teeth Whitening Clinic In Juhu | Oramax",
    MetaDes: "Looking for an instant teeth whitening clinic in Juhu? Achieve a dazzling smile in just a few minutes with our professional and effective teeth whitening.",
    CanonicalTag: "https://oramax.in/instant-teeth-whitening-clinic-juhu",
    Slugs:"/instant-teeth-whitening-clinic-juhu",
  },
  {
    id: 3,
    ServiceIcon: icon_di,
    ServiceName: "Dental Implants",
    ServicePara1:
      "A missing tooth in the front line of upper or lower teeth can be detrimental to your self-image as you may become very conscious about your looks and also shy away from smiling. ",
    ServicePara2:
      "Moreover, missing teeth create a lot of embarrassment as you cannot chew your food properly and also the gaps in teeth make you conscious about your looks. To tackle these embarrassing situations, dental implants were curated to provide a sure-shot solution to replace the missing teeth with new ceramic teeth of equal strength and aesthetics. ",
    ServicePara3:
      "Here’s how the Dental Implants work to bring back the functionality, aesthetics, smile and Confidence back into your life:",
    ServicePara4:
      "Dental implants are revolutionary solution for missing teeth. Our Expert and highly experienced Implantologists at OraMax Juhu make a detailed analysis of your teeth’s condition, gum’s health and past/present medical condition.About You are given counselling on the impending treatment. Through a virtual imaging technology, you are shown the result of the smile makeover even before the treatment begins. A titanium metal screw is inserted into ",
    ServicePara5: "You are given counselling on the impending treatment. Through a virtual imaging technology, you are shown the result of the smile makeover even before the treatment begins. A titanium metal screw is inserted into the gum cavity of the missing teeth. The Dental implantologist keeps a thorough check on its healing till the next session is scheduled. In the following session, a custom-created porcelain crown is fitted on the titanium screw.The Dental crown’s colour is closest to your actual tooth colour to give you the most natural looking new teeth. Apart from providing the aesthetic appeal and augmenting the smile, Dental Implants enable you to chew, grind and bite food like your natural teeth and thereby help you to digest the food well for the better the food is digested; your health tends to improve. Depending on the need for dental implantology, one can go for single tooth replacement or multiple. So say good-bye to denture clasps and viscous adhesives. Get your sparkling new teeth thanks to the revolutionary dental implants at OraMax, Juhu. The OraMax dental care’s Experts Advantage Only specialised implantologists and aesthetic dentists perform the treatment at OraMax juhu The success rate of Dental Implants is almost 99% thereby making it the most preferred solution for replacement of missing teeth. These dental implants are all tested for global quality standards and sterilized before use. Every care is taken to provide guidance and support at every step of the treatment be it from consultation to the final fitting of the implant and post-surgery follow ups. Every doubt is cleared and every lurking fear is addressed.After all, your comfort is our priority."
    ,
    ServiceImg: icon3,
    MetaTitle: "Dental Implants Clinic In Juhu | Oramax",
    MetaDes: "Oramax is a leading dental implants clinic in Juhu. Restore your smile and regain your confidence with our high-quality dental implant solutions. Visit us today",
    CanonicalTag: "https://oramax.in/dental-implants-clinic-juhu",
    Slugs:"/dental-implants-clinic-juhu",


  },
  {

    id: 4,
    ServiceIcon: icon_rc,
    ServiceName: "Painless Root Canal Treatment",
    ServicePara1:
      "At OraMax Juhu, only specialized experts perform specific dental treatments. We are aware that the underlying fear of root canal treatment is one of the reasons that most patients keep ignoring the need for treatment on time as a result the tooth decay worsens leading to the need for root canal treatment or in worst cases, tooth extraction.",
    ServicePara2: " With this key insight, the Expert Endodontists at OraMax Juhu have envisaged a unique solution to put patients fears to rest and to enable them sit through the procedure that is least invasive. After all, we care for our patients at every step of the treatment and want the best solution for them.",
    ServicePara3: " Unlike the fear of conventional root canal treatment, patients have welcomed the innovative treatment as we adopt a very unique way to ensure that they are comfortable and relaxed throughout the procedure. ",
    ServicePara4: "Patients are given a VR Box at OraMax to enjoy their favourite movies during the root canal treatment. So while they are engrossed in watching the movie, they are more at ease and relaxed and less tensed. Sometimes, patients are put to sleep through the procedure as per their preference.     ",

    ServiceImg: icon4,
    MetaTitle: "Painless Root Canal Treatment In Juhu | Oramax",
    MetaDes: "Oramax offers painless root canal treatment in Juhu. Trust our experienced endodontists for expert care and save your natural teeth. Visit Us Now!",
    CanonicalTag: "https://oramax.in/painless-root-canal-treatment-juhu",
    Slugs:"/painless-root-canal-treatment-juhu",
  },
  {
    id: 5,
    ServiceIcon: icon_ib,
    ServiceName: "Invisible Braces",
    ServicePara1:
      "Invisalign consists of a series of 18 to 30 custom-made, mouth guard-like clear plastic aligners. The aligners are removable and are replaced every 2 weeks.  ",
    ServicePara2:
      "They straighten your teeth without wires and brackets, using a series of clear, customized, removable appliances called aligners. It’s virtually undetectable, which means hardly anyone will know that you’re straightening your teeth. ",

    ServiceImg: icon5,
    MetaTitle: "Invisible Braces In Juhu | Oramax",
    MetaDes: "Oramax offers invisible braces in Juhu for a discreet and effective teeth straightening solution. Achieve a confident smile with incisible braces.",
    CanonicalTag: "https://oramax.in/invisible-braces-juhu",
    Slugs:"/invisible-braces-juhu",

  },

];

export default ServDesc





