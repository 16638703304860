import React from 'react'
import '../css/Gallery.css';
import MyBackgroundImage from '../images/page-banner.jpg';
import di from '../images/implant_dental.jpg'
import rc from '../images/root-canal.jpg'
import br from '../images/invisible.jpg'
import white from '../images/Instant-Teeth-Whitening.jpg'
import cosm from '../images/Cosmetic-1.jpg'
import Footer from '../component/Footer';
import g1 from "../images/gallery/1.jpg";
import g2 from "../images/gallery/7.jpg";
import g3 from "../images/gallery/3.jpg";
import g4 from "../images/gallery/08.jpg";
import g5 from "../images/gallery/09.jpg";
import g6 from "../images/gallery/011.jpg";
import g7 from "../images/gallery/012.jpg";
import g8 from "../images/gallery/013.jpg";
import { MetaTags } from 'react-meta-tags';
import { Link } from 'react-router-dom';
const Gallery = () => {
  return (
    <div>

      <MetaTags>
        <title>Gallery Page | Dental Clinic in Juhu - Oramax</title>
        <meta title="Gallery Page | Dental Clinic in Juhu - Oramax" />
        <meta
          name="description"
          content="Visit the Gallery Page of Oramax, a leading dental clinic in Juhu, and witness our exceptional smile transformations and a wide range of dental treatments."
        />
        <meta
          name="keywords"
          content="Most Advanced Dental Clinic In Juhu,Cosmetic Dentistry Clinic In Juhu,Instant Teeth Whitening Clinic In Juhu,Dental Implants Clinic In Juhu,Painless Root Canal Treatment In Juhu,Invisible Braces In Juhu,Dental Clinic in Juhu,Best Dental Clinic In Juhu"
        />
        <link rel="canonical" href="https://oramax.in/gallery-page-dental-clinic-juhu-oramax" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Gallery Page | Dental Clinic in Juhu - Oramax" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://oramax.in/" />
        <meta property="og:description" content="Visit the Gallery Page of Oramax, a leading dental clinic in Juhu, and witness our exceptional smile transformations and a wide range of dental treatments." />
      </MetaTags>

      <section
        className="breadcrumb-area"
        style={{ backgroundImage: `url(${MyBackgroundImage})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="left pull-left">
                <div className="title">
                  <h1>Gallery</h1>
                </div>
              </div>
              <div className="right pull-right">
                <ul>
                  <li>
                    <i className="fa fa-home home" aria-hidden="true" />
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fa fa-angle-right" aria-hidden="true" />
                  </li>
                  <li className="active">
                    <Link to="/gallery-page-dental-clinic-juhu-oramax">Gallery</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="main-project-area">
        <div className="container">
          <div className="row">
            {/*Start single project item*/}
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-project-item">
                <div className="img-holder">
                  <img src={g1} alt="instant whitening treatment" />
                  <div className="overlay-style-one">
                    <div className="box">
                      <div className="content">
                        <div className="text-holder">
                          <a href="project-single.html">
                            <h3>Teeth Replacement</h3>
                          </a>
                          <span className="border" />
                          <p>Dental Care</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-project-item">
                <div className="img-holder">
                  <img src={g2} alt="best dental implants clinic" />
                  <div className="overlay-style-one">
                    <div className="box">
                      <div className="content">
                        <div className="text-holder">
                          <a href="project-single.html">
                            <h3>Teeth Replacement</h3>
                          </a>
                          <span className="border" />
                          <p>Dental Care</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-project-item">
                <div className="img-holder">
                  <img src={g3} alt="best dental implants in mumbai" />
                  <div className="overlay-style-one">
                    <div className="box">
                      <div className="content">
                        <div className="text-holder">
                          <a href="project-single.html">
                            <h3>Teeth Replacement</h3>
                          </a>
                          <span className="border" />
                          <p>Dental Care</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-project-item">
                <div className="img-holder">
                  <img src={g4} alt="invisible braces in mumbai" />
                  <div className="overlay-style-one">
                    <div className="box">
                      <div className="content">
                        <div className="text-holder">
                          <a href="project-single.html">
                            <h3>Teeth Replacement</h3>
                          </a>
                          <span className="border" />
                          <p>Dental Care</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-project-item">
                <div className="img-holder">
                  <img src={g5} alt="best cosmetic dentist in mumbai" />
                  <div className="overlay-style-one">
                    <div className="box">
                      <div className="content">
                        <div className="text-holder">
                          <a href="project-single.html">
                            <h3>Teeth Replacement</h3>
                          </a>
                          <span className="border" />
                          <p>Dental Care</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-project-item">
                <div className="img-holder">
                  <img src={g6} alt="painless root canal treatment in mumbai" />
                  <div className="overlay-style-one">
                    <div className="box">
                      <div className="content">
                        <div className="text-holder">
                          <a href="project-single.html">
                            <h3>Teeth Replacement</h3>
                          </a>
                          <span className="border" />
                          <p>Dental Care</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-project-item">
                <div className="img-holder">
                  <img src={g7} alt="cosmetic dentistry in mumbai" />
                  <div className="overlay-style-one">
                    <div className="box">
                      <div className="content">
                        <div className="text-holder">
                          <a href="project-single.html">
                            <h3>Teeth Replacement</h3>
                          </a>
                          <span className="border" />
                          <p>Dental Care</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-project-item">
                <div className="img-holder">
                  <img src={g8} alt="Crown and Bridges" />
                  {/* <div className="overlay-style-one">
                    <div className="box">
                      <div className="content">
                        <div className="text-holder">
                          <a href="project-single.html">
                            <h3>Teeth Replacement</h3>
                          </a>
                          <span className="border" />
                          <p>Dental Care</p>
                        </div>
                      </div>
                    </div>
                  </div> */}

                </div>
              </div>
            </div>
            {/*End single project item*/}
            {/*Start single project item*/}

          </div>

        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Gallery