import React, { useState } from "react";
import dent from "../images/dent-icon.png";
import { useLocation } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "../css/Service.css";
import ServDesc from "./ServDesc";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import Footer from "../component/Footer.js";
import MyBackgroundImage from "../images/page-banner.jpg";
import { MetaTags } from "react-meta-tags";
const Service = () => {
  const search = useLocation();
  const Path = search.pathname;
  const [serviceData, setServiceData] = useState(ServDesc);

  const FData = serviceData.find((e) => e.Slugs == Path);

  console.log(FData.ServiceName);

  return (
    <div>
      <MetaTags>
        <title>{FData.MetaTitle}</title>
        <meta title={FData.MetaTitle} />
        <meta name="description" content={FData.MetaDes} />
        <meta
          name="keywords"
          content="Most Advanced Dental Clinic In Juhu,Cosmetic Dentistry Clinic In Juhu,Instant Teeth Whitening Clinic In Juhu,Dental Implants Clinic In Juhu,Painless Root Canal Treatment In Juhu,Invisible Braces In Juhu,Dental Clinic in Juhu,Best Dental Clinic In Juhu"
        />
        <link
          rel="canonical"
          href={FData.CanonicalTag}
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content={FData.MetaTitle} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://oramax.in/" />
        <meta property="og:description" content={FData.MetaDes} />
      </MetaTags>

      <section
        className="breadcrumb-area"
        style={{ backgroundImage: `url(${MyBackgroundImage})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="left pull-left">
                <div className="title">
                  <h1>{FData.ServiceName}</h1>
                </div>
              </div>
              <div className="right pull-right">
                <ul>
                  <li>
                    <i className="fa fa-home home" aria-hidden="true" />
                    <a href="index.html">Home</a>
                  </li>
                  <li>
                    <i className="fa fa-angle-right" aria-hidden="true" />
                  </li>
                  <li className="active">{FData.ServiceName}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="special-services-area">
        <div className="container">
          <div className="row">
            <div className="row">
              <div className="col-lg-4 col-md-3 col-12">
                <div className="serImg">
                  <img src={FData.ServiceImg}></img>
                </div>
              </div>
              <div className="col-lg-8  col-md-9 col-12">
                <div class="serDesc">
                  <h2>{FData.ServiceName}</h2>
                  <p>{FData.ServicePara1}</p>
                  <p>{FData.ServicePara2}</p>
                  <p>{FData.ServicePara3}</p>
                  <p>{FData.ServicePara4}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ------------------------testimonial------------------------- */}
      <section className="testimonial-area" id="testi">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="left-content">
                <div className="sec-title">
                  <h2>
                    Words From
                    <br /> Our Happy Clients
                  </h2>
                  <span className="bord" />
                </div>
                <div className="text-holder">
                  <p>
                    We are glad and honoured to have such kind of patients that
                    appreciate us and our work.Thanks to all for precious
                    reviews.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="testimonial-carousel owl-carousel owl-theme owl-loaded">
                <Swiper
                  navigation={true}
                  breakpoints={{
                    570: {
                      slidesPerView: 1,
                      spaceBetween: 30,
                    },
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 30,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 1,
                      spaceBetween: 50,
                    },
                  }}
                  modules={[Navigation]}
                  className="mySwiper1"
                >
                  <SwiperSlide>
                    <div className="single-item">
                      <div className="text-holder">
                        <div className="quote-icon">
                          <i className="fa fa-quote-right" aria-hidden="true" />
                        </div>
                        <h3>Dimpy Mehta</h3>
                        <p>
                          Dr Khushal Gangwani is an excellent dentist based in
                          Juhu . He not only answered my call on a Sunday ,
                          prescribed me medication and the next day removed my
                          wisdom tooth explaining me the procedure. Highly
                          recommended.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="single-item">
                      <div className="text-holder">
                        <div className="quote-icon">
                          <i className="fa fa-quote-right" aria-hidden="true" />
                        </div>
                        <h3>Hare Krsna Bliss</h3>
                        <p>
                          Dr Khushal Gangwani is an excellent oral and
                          maxillofacial surgeon with a great hand. he does his
                          job with so much ease and patience and makes his
                          patients feel so much comfortable that you never
                          realise how quickly you finished your treatment. i got
                          my wisdom tooth removed surgically in just 10min
                          painlessly. the recovery was smooth. highly
                          recommended
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="single-item">
                      <div className="text-holder">
                        <div className="quote-icon">
                          <i className="fa fa-quote-right" aria-hidden="true" />
                        </div>
                        <h3>Shinoy Chenetra</h3>
                        <p>
                          Oramax is an amazing clinic. Dr. Khushal Gangwani is
                          extremely professional. I had gone to him for a wisdom
                          tooth extraction and was quite worried about the
                          surgery. He made me very comfortable and answered all
                          my doubts and cleared my apprehensions. Although I was
                          the patient, he was way more patient than me. 😅 I
                          would recommend him to everyone who wants really good
                          treatment for any dental issues.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="single-item">
                      <div className="text-holder">
                        <div className="quote-icon">
                          <i className="fa fa-quote-right" aria-hidden="true" />
                        </div>
                        <h3>Amee Desai</h3>
                        <p>
                          Dr. Khushal is such a cool dentist. I got over my fear
                          of dentist's visit after i met him. First time ever i
                          went on my visit all alone as the treatments were
                          absolutely painless. My root canal was completed in 1
                          session and then just another session for crown. If
                          you feel dentist and dental treatments are scary, plz
                          go and visit Dr. Khushal. You will definitely overcome
                          it. He explains each process so well and is so kind to
                          his patients. I have found a dentist for a lifetime.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="single-item">
                      <div className="text-holder">
                        <div className="quote-icon">
                          <i className="fa fa-quote-right" aria-hidden="true" />
                        </div>
                        <h3>Damodarrsing Surnam</h3>
                        <p>
                          I am from Mauritius. Me and my wife attended the
                          Oramax Dental Clinic for treatment of our teeth. My
                          wife did a full mouth implants and I had roots canal
                          treatment and placement of Zirconia false teeth. The
                          treatments were done by Dr K Gangwani professionally
                          without pain, smoothly and very quick as the visits
                          were well organised and at reasonable and affordable
                          price. We both are fully satisfied. A big thank you to
                          Dr K Gangwani and his team. I would highly recommend
                          to carry out your teeth treatment at Oramax Dental
                          Clinic.
                        </p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Service;
