import React from "react";
import "../css/About.css";
import Footer from "../component/Footer.js";
import sign from "../images/sign.jpg";
import aboutPic from "../images/about-page-img.jpg";
import icon1 from "../images/icons-i/dental-care.png";
import icon1_1 from "../images/icons-i/tooth.png";
import icon1_2 from "../images/icons-i/clinic (1).png";
import icon1_3 from "../images/icons-i/lips.png";
import icon1_4 from "../images/icons-i/tooth-extraction.png";
import icon1_5 from "../images/icons-i/clinic.png";
import icon1_6 from "../images/icons-i/dental-crown.png";
import MyBackgroundImage from "../images/page-banner.jpg";
import miss from "../images/mission.jpg";
import vis from "../images/abt2.jpg";
import Fade from "react-reveal/Fade";
import { MetaTags } from 'react-meta-tags';
import { Link } from "react-router-dom";
const About = () => {
  return (
    <div>


      <MetaTags>
        <title>Most Advanced Dental Clinic In Juhu | Oramax</title>
        <meta title="Most Advanced Dental Clinic In Juhu | Oramax" />
        <meta
          name="description"
          content="Looking for the most advanced dental clinic in Juhu? Visit our clinic for top-notch dental services and personalized care. Call Us Now!"
        />
        <meta
          name="keywords"
          content="Most Advanced Dental Clinic In Juhu,Cosmetic Dentistry Clinic In Juhu,Instant Teeth Whitening Clinic In Juhu,Dental Implants Clinic In Juhu,Painless Root Canal Treatment In Juhu,Invisible Braces In Juhu,Dental Clinic in Juhu,Best Dental Clinic In Juhu"
        />
        <link rel="canonical" href="https://oramax.in/advanced-dental-clinic-juhu" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Most Advanced Dental Clinic In Juhu | Oramax" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://oramax.in/" />
        <meta property="og:description" content="Looking for the most advanced dental clinic in Juhu? Visit our clinic for top-notch dental services and personalized care. Call Us Now!" />
      </MetaTags>


      <section
        className="breadcrumb-area"
        style={{ backgroundImage: `url(${MyBackgroundImage})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="left pull-left">
                <div className="title">
                  <h1>About Us</h1>
                </div>
              </div>
              <div className="right pull-right">
                <ul>
                  <li>
                    <i className="fa fa-home home" aria-hidden="true" />
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i className="fa fa-angle-right" aria-hidden="true" />
                  </li>
                  <li className="active">
                    <Link to="/advanced-dental-clinic-juhu">
                      About Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-page-us-area">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-lg-2 order-sm-1">
              <div className="img-holder">
                <img src={aboutPic} alt="Lasers in Endodontics" />
              </div>
              <div className="text pt-2">
                <p>
                  Management of Endodontic Mishaps like: Perforations. Separated
                  instrument management. Esthetic Restorations like Composite
                  laminates, Veeners, Composite build ups, Full mouth.
                  rehabilitation. Custom cast posts. Crown and Bridges.
                  Bleaching (Vital & Non-vital cases). Performed Endodontic
                  surgeries like Surgical Extrusions, Hemisections,
                  Apicoectomies. Lasers in Endodontics. Microscope usage in
                  Endodontics.
                </p>
              </div>
            </div>
            <div className="col-md-6 order-lg-1 order-sm-2">
              <div className="text-holder martop50">
                <div className="sec-title">
                  <h2>Welcome to Oramax</h2>
                  <span className="bord" />
                </div>
                <div className="text">
                  <h3>One of the finest &amp; most advanced dental clinic</h3>
                  <p>
                    Compassionate Endodontist, well-versed in four-handed chair
                    side dentistry, root canal treatments, esthetic
                    restorations, routine restorations and intra-oral
                    techniques.
                  </p>
                  <p>
                    Areas of interest Root canal treatment, Performed more than
                    800 RCTs Retreatment of failed Root canal therapies,
                    Performed more than 200 cases Single Visit Endodontics,
                    Performed more than 200 cases Apexifications, 50 cases
                    Non-surgical management of periapical lesions, Performed
                    more than 50 cases, Management of resorptive defects,
                    Management of Endo-Perio lesions
                  </p>
                  {/* <div className="button-and-signature">
              <a className="thm-btn bg-1" href="#">
                Meet Dentist
              </a>
              <div className="signature">
                <img src={sign} alt="Signature" />
              </div>
            </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-lg-5 mt-2">
            {/*Start single item*/}
            <div className="col-md-4">
              <Fade down>
                <div className="single-item text-center">
                  <div className="icon-holder">
                    <i className="fa fa-medkit" />
                  </div>

                  <div className="text-holder">
                    <h3>General &amp; Cosmetic</h3>
                    <p>
                      General Cosmetic dentistry aims to create a more balanced, symmetrical and aesthetically pleasing smile.
                    </p>
                  </div>
                </div>
              </Fade>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4">
              <Fade down>
                <div className="single-item text-center">
                  <div className="icon-holder">
                    <i className="fa fa-stethoscope" />
                  </div>
                  <div className="text-holder">
                    <h3>Dental Surgeries</h3>
                    <p>
                      Advanced dental surgeries to get rid of your dental problems and smile for yourself.

                    </p>
                  </div>
                </div>
              </Fade>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-md-4">
              <Fade down>
                <div className="single-item text-center">
                  <div className="icon-holder">
                    <i className="fa fa-ambulance" />
                  </div>
                  <div className="text-holder">
                    <h3>Laser Treatments</h3>
                    <p>
                      Laser dentistry can cut weeks off of healing time and nearly eliminate pain associated with traditional dental services.
                    </p>
                  </div>
                </div>
              </Fade>
            </div>
            {/*End single item*/}
          </div>

          <div className="row">
            <div className="col-md-12">
              <span className="hr-border" />
            </div>
          </div>
          <div className="row mt-lg-5 mt-2">
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-item">
                <div className="img-holder">
                  <img src={miss} alt="dental care" />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="single-item">
                <div className="text-holder martop30">
                  <h3>Our Mission</h3>
                  <p>
                    It is our mission to exceed expectations by providing
                    exceptional dental care to our patients and at the same time,
                    building relationships of trust with them.
                  </p>
                  {/* <a className="readmore" href="#">
                  Read More
                  <i className="fa fa-caret-right" aria-hidden="true" />
                </a> */}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-12">
              <div className="single-item martop40">
                <div className="img-holder">
                  <img src={vis} alt="patient care" />
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-12">
              <div className="single-item">
                <div className="text-holder martop30">
                  <h3>Our Vision</h3>
                  <p>
                    Our vision is to provide our patients with a dental experience
                    that will promote a lifelong relationship built on trust,
                    confidence, quality of work, and exceptional patient care.
                  </p>
                  {/* <a className="readmore" href="#">
                  Read More
                  <i className="fa fa-caret-right" aria-hidden="true" />
                </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ---------------------------achievements----------------------- */}
      <section className="fact-counter-area">
        <div className="container">
          <div className="row">
            <div className="sec-title text-center">
              <h2>Our Achivements</h2>
              {/* <p className=" text-center">
       
      </p> */}
            </div>
            {/*Start single item*/}
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="single-item text-center">
                <div className="icon-holder">
                  <i className="fa fa-user-md" />
                </div>
                <h2>
                  <span
                    className="timer"
                    data-from={1}
                    data-to={25}
                    data-speed={5000}
                    data-refresh-interval={50}
                  >
                    25
                  </span>
                </h2>
                <h3>Years of Experience</h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="single-item text-center">
                <div className="icon-holder">
                  <i className="fa fa-medkit" />
                </div>
                <h2>
                  <span
                    className="timer"
                    data-from={1}
                    data-to={176}
                    data-speed={5000}
                    data-refresh-interval={50}
                  >
                    176
                  </span>
                </h2>
                <h3>Cavities Fixed</h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="single-item text-center">
                <div className="icon-holder">
                  <i className="fa fa-smile-o" />
                </div>
                <h2>
                  <span
                    className="timer"
                    data-from={1}
                    data-to={284}
                    data-speed={5000}
                    data-refresh-interval={50}
                  >
                    284
                  </span>
                </h2>
                <h3>Well Smiley Faces</h3>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
              <div className="single-item text-center">
                <div className="icon-holder">
                  <i className="fa fa-trophy" />
                </div>
                <h2>
                  <span
                    className="timer"
                    data-from={1}
                    data-to={142}
                    data-speed={5000}
                    data-refresh-interval={50}
                  >
                    142
                  </span>
                </h2>
                <h3>Awards Holded</h3>
              </div>
            </div>
            {/*End single item*/}
          </div>
        </div>
      </section>
      {/* ----------------------------serve section------------------ */}
      <section className="services-area">
        <div className="container">
          <div className="sec-title text-center">
            <h2>We Specialize in</h2>
            <span className="border" />
          </div>
          <div className="row">
            {/*Start single item*/}
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="single-item hvr-float-shadow">
                <div className="icon-holder">
                  <div className="icon-box">
                    <div className="icon">
                      <div className="icon-bg">
                        <span>
                          <img src={icon1} alt="Fluoride"/>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-holder">
                  <h3>Fluoride</h3>
                  <p>
                    Fluoride is especially helpful if you’re at high risk of
                    developing dental caries, or cavities.
                  </p>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="single-item hvr-float-shadow">
                <div className="icon-holder">
                  <div className="icon-box">
                    <div className="icon">
                      <div className="icon-bg">
                        <span className="flaticon-drink">
                          <img src={icon1_2} alt="Filling"/>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-holder">
                  <h3>Filling</h3>
                  <p>
                    Dental Filling is a treatment modality to restore missing
                    tooth structure which could have been a result of decay or
                    trauma.
                  </p>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="single-item hvr-float-shadow">
                <div className="icon-holder">
                  <div className="icon-box">
                    <div className="icon">
                      <div className="icon-bg">
                        <span className="flaticon-avatar">
                          <img src={icon1_3} alt="Space maintainers" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-holder">
                  <h3>Space maintainers</h3>
                  <p>
                    Space Maintainer is a device used by our dentist that
                    occupies the lost tooth's place until the adult tooth
                    erupts.
                  </p>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="single-item hvr-float-shadow">
                <div className="icon-holder">
                  <div className="icon-box">
                    <div className="icon">
                      <div className="icon-bg">
                        <span className="flaticon-church">
                          <img src={icon1_4} alt="Extractions"/>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-holder">
                  <h3>Extractions</h3>
                  <p>
                    Tooth extraction is a procedure to remove a tooth from the
                    gum socket.
                  </p>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="single-item hvr-float-shadow">
                <div className="icon-holder">
                  <div className="icon-box">
                    <div className="icon">
                      <div className="icon-bg">
                        <span className="flaticon-technology">
                          <img src={icon1_5} alt="Deep Cleaning"/>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-holder">
                  <h3>Deep Cleaning</h3>
                  <p>
                    Deep cleaning is necessary in order to remove any plaque or
                    tartar buildup occurring on the teeth.
                  </p>
                </div>
              </div>
            </div>
            {/*End single item*/}
            {/*Start single item*/}
            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="single-item hvr-float-shadow">
                <div className="icon-holder">
                  <div className="icon-box">
                    <div className="icon">
                      <div className="icon-bg">
                        <span className="flaticon-medical-1">
                          <img src={icon1_6} alt="Crowns"/>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-holder">
                  <h3>Crowns</h3>
                  <p>
                    Crowns are used to protect, cover and restore the shape of
                    your teeth.
                  </p>
                </div>
              </div>
            </div>
            {/*End single item*/}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default About;
