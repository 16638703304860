import React from 'react'
import { HashRouter, Routes, Route } from 'react-router-dom';
import NavBarCom from './component/NavBarCom';
import Home from './component/Home';
import About from './component/About';
import Service from './component/Service';
import Contact from './component/Contact';
import Gallery from './component/Gallery';
// import Franchise from './component/Franchise';
// import Contact from './component/Contact';
import { BrowserRouter } from 'react-router-dom';
import ServDesc from './component/ServDesc';

const App = () => {
  return (
    <BrowserRouter>
      <NavBarCom />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/advanced-dental-clinic-juhu' element={<About />} />
        <Route path='/gallery-page-dental-clinic-juhu-oramax' element={<Gallery />} />
        <Route path='/contactus-dental-clinic-juhu' element={<Contact />} />

        {ServDesc.map((route, index) => (
          <Route
            key={index}
            exact
            path={route.Slugs}
            element={<Service />}
          />
        ))}

      </Routes>
    </BrowserRouter>
  )
}

export default App