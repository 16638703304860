import React from 'react'
import '../css/Footer.css'
import di from '../images/implant_dental.jpg'
import rc from '../images/root-canal.jpg'
import br from '../images/invisible.jpg'
import white from '../images/Instant-Teeth-Whitening.jpg'
import cosm from '../images/Cosmetic-1.jpg'
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div>
        <footer className="footer-area">
  <div className="container">
    <div className="row">
      {/*Start single footer widget*/}
      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <div className="single-footer-widget pt-2">
          <div className="title">
            <h3>About Us</h3>
            <span className="border" />
          </div>
          <div className="our-info">
            <p>
            Compassionate Endodontist, well-versed in four-handed chair side dentistry, root canal treatments, esthetic restorations, routine restorations and intra-oral techniques.
            </p>
            <ul className="footer-social-links">
              <li>
                <a href="https://www.facebook.com/Oramaxclinic/">
                  <i className="fa fa-facebook" aria-hidden="true" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/oramaxdentalclinic/">
                  <i className="fa fa-instagram" aria-hidden="true" />
                </a>
              </li>
              <li>
                <a href="https://goo.gl/maps/pt2bPfCx8HYMTTtf7">
                  <i className="fa fa-google-plus" aria-hidden="true" />
                </a>
              </li>
             
            </ul>
          </div>
        </div>
      </div>
      {/*End single footer widget*/}
      {/*Start single footer widget*/}
      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <div className="single-footer-widget  pt-2">
          <div className="title">
            <h3>Quick Links</h3>
            <span className="border" />
          </div>
          <ul className="quick-links">
          <li>
              <Link to="/advanced-dental-clinic-juhu">About Us</Link>
            </li>
            <li>
              <HashLink to="/#team">Doctors</HashLink>
            </li>
           
            <li>
              <HashLink to="/#testi">Testimonials</HashLink>
            </li>
            <li>
              <Link to="/gallery-page-dental-clinic-juhu-oramax">Gallery</Link>
            </li>
            <li>
              <Link to="/contactus-dental-clinic-juhu">Contact us</Link>
            </li>
          </ul>
        </div>
      </div>
      {/*Start single footer widget*/}
      {/*Start single footer widget*/}
      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <div className="single-footer-widget  pt-2">
          <div className="title">
            <h3>Reach Us</h3>
            <span className="border" />
          </div>
          <ul className="footer-contact-info">
            <li>
              <div className="icon-holder">
                <i className="fa fa-map-marker" />
              </div>
              <div className="text-holder">
                <h5>Flat no-8, Sukh Shanti, N S Rd Number 8, Nutan Laxmi Society, JVPD Scheme, Juhu, Mumbai, Maharashtra 400049</h5>
              </div>
            </li>
           
          </ul>
        </div>
      </div>
      {/*End single footer widget*/}
      {/*Start single footer widget*/}
      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <div className="single-footer-widget margintop50">
          <div className="title">
            <h3>Get in touch</h3>
            <span className="border" />
          </div>
          <ul className="footer-contact-info">
            {/* <li>
              <div className="icon-holder">
                <i className="fa fa-map-marker" />
              </div>
              <div className="text-holder">
                <h5>Flat no-8, Sukh Shanti, N S Rd Number 8, Nutan Laxmi Society, JVPD Scheme, Juhu, Mumbai, Maharashtra 400049</h5>
              </div>
            </li> */}
            <li>
              <div className="icon-holder">
                <i className="fa fa-phone" />
              </div>
              <div className="text-holder">
                <h5>+91 98239 00884</h5>
              </div>
            </li>
            <li>
              <div className="icon-holder">
                <i className="fa fa-envelope" />
              </div>
              <div className="text-holder">
                <h5>oramaxdental@gmail.com</h5>
              </div>
            </li>
            <li>
              <div className="icon-holder">
                <i className="fa fa-clock-o" />
              </div>
              <div className="text-holder">
                <h5>Mon-Sat: 9.30am to 8.00pm</h5>
              </div>
            </li>
          </ul>
          {/* <ul className="latest-work-list">
            <li>
              <div className="image-holder">
                <img
                  src={di}
                  alt="Awesome Image"
                />
                <div className="overlay-style-one">
                  <div className="box">
                    <div className="content">
                      <a href="#">
                        <i className="fa fa-link" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="image-holder">
                <img
                  src={rc}
                  alt="Awesome Image"
                />
                <div className="overlay-style-one">
                  <div className="box">
                    <div className="content">
                      <a href="#">
                        <i className="fa fa-link" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="image-holder">
                <img
                  src={br}
                  alt="Awesome Image"
                />
                <div className="overlay-style-one">
                  <div className="box">
                    <div className="content">
                      <a href="#">
                        <i className="fa fa-link" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="image-holder">
                <img
                  src={white}
                  alt="Awesome Image"
                />
                <div className="overlay-style-one">
                  <div className="box">
                    <div className="content">
                      <a href="#">
                        <i className="fa fa-link" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="image-holder">
                <img
                  src={cosm}
                  alt="Awesome Image"
                />
                <div className="overlay-style-one">
                  <div className="box">
                    <div className="content">
                      <a href="#">
                        <i className="fa fa-link" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div className="image-holder">
                <img
                  src={white}
                  alt="Awesome Image"
                />
                <div className="overlay-style-one">
                  <div className="box">
                    <div className="content">
                      <a href="#">
                        <i className="fa fa-link" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul> */}
        </div>
      </div>
      {/*End single footer widget*/}
    </div>
  </div>
</footer>
<section class="footer-bottom-area">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="footer-bottom">
                    <div class="copyright-text pull-left">
                        <p>Copyrights © 2017 All Rights Reserved by <a href="#">Oramax.</a></p> 
                    </div>
                    <div class="footer-menu pull-right">
                    <p>Developed by <a href="https://skdm.in/">Shree Krishna Digital Marketing .</a></p> 
                    </div>
                </div>    
            </div>
        </div>
    </div>    
</section>
<div className="floattingButton ">
  <ul>
    <li>
      <a href="https://wa.me/+9823900884">
        <span>whatsapp</span>{" "}
      </a>
      <i className="fa fa-whatsapp my-float " />
    </li>
    {/* <li>
      <a href="tel:+91 ">
        <span>call</span>
      </a>
      <i className="fa fa-phone my-float " />
    </li> */}
    <li> </li>
  </ul>
</div>

{/* <div
  className="scroll-to-top scroll-to-target"
  data-target="html"
  style={{ display: "block" }}
>
  <span className="fa fa-angle-up" />
</div> */}

        </div>
  )
}

export default Footer